import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/service-agreement',
    name: 'service-agreement',
    meta:{
      title:'服务协议'
    },
    component: () =>
      import(
        /* webpackChunkName: "ServiceAgreement" */ '../views/service-agreement.vue'
      ),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    meta:{
      title:'隐私政策'
    },
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */ '../views/privacy-policy.vue'
      ),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) { //如果设置标题，拦截后设置标题
    document.title = to.meta.title
  }
  next()
})

export default router
